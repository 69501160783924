import Swiper from "swiper"
const $ = require("jquery") // if we need
import SwiperCore, {
  Navigation,
  Thumbs,
  EffectFade,
  EffectCube,
  Autoplay,
  Pagination,
} from "swiper/core"
import "swiper/swiper-bundle.css"

SwiperCore.use([
  Navigation,
  Thumbs,
  EffectFade,
  EffectCube,
  Autoplay,
  Pagination,
])

var app = {
  load() {
    console.log("load")
  },
  resized() {
    console.log("resized")
  },

  mobileButton() {
    const mobileMenuBtn = document.querySelector(".js-mobile-menu-trigger")
    const mobileMenu = document.querySelector(".js-mobile-menu")
    if (mobileMenu) {
      mobileMenuBtn.addEventListener("click", () => {
        mobileMenuBtn.classList.toggle("active")
        mobileMenu.classList.toggle("active")
      })
    }
  },

  stickyHeaderFn() {
    const header = document.querySelector(".header")
    if (header) {
      setTimeout(() => {
        if (window.scrollY >= 100) {
          header.classList.add("header--sticky")
        } else {
          header.classList.remove("header--sticky")
        }
      }, 400)
    }
  },

  headerSearchFn() {
    const searchBtn = document.querySelector(".header__search-top")
    const Input = document.querySelector(".header__search-input input")
    if (searchBtn) {
      searchBtn.addEventListener("click", () => {
        document
          .querySelector(".header__search-input")
          .classList.toggle("opened")
        document
          .querySelector(".header__search-results")
          .classList.remove("opened")
        document.querySelector(".header__search-top").classList.toggle("opened")
        document
          .querySelector(".header__search-overlay")
          .classList.toggle("opened")
        Input.value = ""
      })
    }
    if (document.querySelector(".header__search-overlay")) {
      document
        .querySelector(".header__search-overlay")
        .addEventListener("click", (e) => {
          e.stopPropagation()
          document
            .querySelector(".header__search-input")
            .classList.remove("opened")
          document
            .querySelector(".header__search-results")
            .classList.remove("opened")
          document
            .querySelector(".header__search-top")
            .classList.remove("opened")
          document
            .querySelector(".header__search-overlay")
            .classList.remove("opened")
          Input.value = ""
        })
    }

    if (Input) {
      Input.addEventListener("keyup", () => {
        if (Input.value.length > 3) {
          document
            .querySelector(".header__search-results")
            .classList.add("opened")
        } else {
          document
            .querySelector(".header__search-results")
            .classList.remove("opened")
        }
      })
    }
    if (document.querySelector(".header__language-top")) {
      document
        .querySelector(".header__language-top")
        .addEventListener("click", function () {
          document
            .querySelector(".header__language-box")
            .classList.toggle("active")
        })
    }
  },

  heroSwiper() {
    var jsHeroSliderImg = new Swiper(".js-hero-slider-img", {
      spaceBetween: 0,
      effect: "cube",
      speed: 1000,
      cubeEffect: {
        shadow: true,
        slideShadows: true,
        shadowOffset: 0,
        shadowScale: 0,
      },
      slidesPerView: 1,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
    })
    var jsHeroSliderText = new Swiper(".js-hero-slider-text", {
      spaceBetween: 0,
      effect: "fade",
      speed: 500,
      fadeEffect: {
        crossFade: true,
      },
      navigation: {
        nextEl: ".hero .swiper-button-next",
        prevEl: ".hero .swiper-button-prev",
      },
      thumbs: {
        swiper: jsHeroSliderImg,
      },
      pagination: {
        el: ".hero .swiper-pagination",
        type: "fraction",
      },
      renderBullet: function (index, className) {
        console.log(index + className)
        return '<span class="' + className + '">' + (index + 1) + "</span>"
      },
    })
  },
  expertisesSwiper() {
    var jsExpertisesSlider = new Swiper(".js-expertises-slider ", {
      spaceBetween: 40,
      slidesPerView: 3.15,
      initialSlide: 2,
      freeMode: true,
      autoplay: {
        pauseOnMouseEnter: true,
        delay: 1500,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        250: {
          slidesPerView: 1.24,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 1.5,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 3.2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3.2,
          spaceBetween: 40,
        },
      },
      loop: true,
      centeredSlides: true,
      speed: 1000,
    })
  },
  expertisesDetailSwiper() {
    var jsExpertisesSlider = new Swiper(".js-expertises-detail-slider ", {
      spaceBetween: 40,
      slidesPerView: 2.3,
      initialSlide: 2,
      freeMode: true,
      autoplay: {
        pauseOnMouseEnter: true,
        delay: 1500,
        disableOnInteraction: false,
      },
      breakpoints: {
        250: {
          slidesPerView: 1.24,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 1.5,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2.3,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3.2,
          spaceBetween: 40,
        },
      },
      loop: true,
      centeredSlides: true,
      speed: 1000,
    })
  },
  publicationsSwiper() {
    var jsExpertisesSlider = new Swiper(".js-publications-slider ", {
      spaceBetween: 40,
      slidesPerView: 1.8,
      initialSlide: 2,
      freeMode: true,
      autoplay: {
        pauseOnMouseEnter: true,
        delay: 1500,
        disableOnInteraction: false,
      },
      breakpoints: {
        250: {
          slidesPerView: 1.24,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 1.5,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2.3,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 1.8,
          spaceBetween: 40,
        },
      },
      loop: true,
      centeredSlides: true,
      speed: 1000,
    })
  },
  accordionFn() {
    $("body").on("click", ".js-accordion-trigger", function () {
      $(this).toggleClass("active")
      const accordionGroup = $(this).closest(".accordion-wrapper")
      const item = $(this).closest(".accordion-item")
      let multipleShow = false

      if (accordionGroup.data("multiple-show") == true) {
        multipleShow = true
      } else {
        $(".js-accordion-trigger").not(this).removeClass("active")
      }
      let content = item.find(".accordion-calc")
      let contentHeight = content.outerHeight(true)
      if (item.hasClass("active") && !$(this).hasClass("force-open")) {
        item.find(".accordion-content").css("height", 0 + "px")
        item.removeClass("active")
      } else {
        if (!multipleShow) {
          accordionGroup.children(".accordion-item").removeClass("active")
          accordionGroup
            .children(".accordion-item")
            .find(".accordion-content")
            .css("height", 0 + "px")
        }
        item.addClass("active")
        item.find(".accordion-content").css("height", contentHeight)
      }
    })

    $(".accordion-item.opened .js-accordion-trigger").trigger("click")
  },
  inputFocusandTexted() {
    $(".input-item input, .input-item textarea").focus(function () {
      $(this).parent(".input-item").addClass("input-item--focused")
    })
    $(".input-item input, .input-item textarea").focusout(function () {
      if ($(this).val() === "") {
        $(this).parent(".input-item").removeClass("input-item--focused")
      } else {
        $(this).parent(".input-item").addClass("input-item--focused")
      }
    })

    $(".input-item input, .input-item textarea").each(function () {})

    if (document.querySelector(".notfound")) {
      document.querySelector("body").style.zoom = "100%"
      document.querySelector("html").style.height = "100%"
      document.querySelector("body").style.height = "100%"
    } else {
      document.querySelector("body").style.zoom = "80%"
    }
  },

  init: function () {
    app.load()
    app.mobileButton()
    app.stickyHeaderFn()
    app.headerSearchFn()
    setTimeout(() => {
      app.heroSwiper()
    }, 300)
    app.expertisesSwiper()
    app.expertisesDetailSwiper()
    app.publicationsSwiper()
    app.accordionFn()
    app.inputFocusandTexted()
  },
}

function docReadied(fn) {
  window.addEventListener("DOMContentLoaded", fn)
}
function docResized(fn) {
  window.addEventListener("resize", fn)
}
function docScrolled(fn) {
  window.addEventListener("scroll", fn)
}

docReadied(() => {
  app.init()
})

docResized(() => {
  app.resized()
})
docScrolled(() => {
  app.stickyHeaderFn()
})

export default { docResized, docReadied, docScrolled }
